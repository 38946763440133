<template>
	<div class="contact-form">
		<div
				class="success-message animate__animated"
				:class="[{ animate__zoomIn: !loading && sent && success }, { animate__zoomOut: loading || !sent || !success }]"
		>
			<p class="fs-48 lh-10 color-beige capital">congrats!</p>
			<p class="fs-32 lh-144 color-white mar-top-32">You Are in, We will call you Soon!</p>
		</div>
		<form class="form beige relative z-9" @submit.prevent="submitForm" ref="form">
			<div class="fields-grid">
				<div class="field">
					<input name="first_name" type="text" placeholder="First name" required/>
				</div>
				<div class="field">
					<input name="last_name" type="text" placeholder="Last name" required/>
				</div>

				<div class="field">
					<input name="email" type="email" placeholder="Email" required/>
				</div>

				<div class="field">
					<input name="phone" type="text" placeholder="Phone" required/>
				</div>
				<div class="field field-100">
					<textarea name="message" placeholder="Your message" rows="5" required></textarea>
				</div>
				<div class="messages-cont">
          <span class="sending animate__animated trans-3"
                :class="[{ animate__fadeInLeft: loading }, { animate__fadeOutLeft: !loading }]">
            <span class="mr-1 fs-14 fw-700">Sending</span>
            <loading class="beige"></loading>
          </span>
				</div>
				<div class="field field-100 al-right">
					<button
							:class="[{ 'wait-loading': loading }, { success: success }, { off: !loading && sent && success }]"
							:disabled="loading"
							class="trans-3 btn beige animate__animated"
					>
						Lets go!
					</button>
				</div>
			</div>
		</form>
	</div>
</template>

<script>
import Loading from "@/js/components/loading";

export default {
	name: "ContactForm",
	components: {Loading},
	data: () => {
		return {
			loading: false,
			success: false,
			sent: false,
			form: null,
			token: null
		};
	},
	methods: {
		setUp() {
			let tokenTag = document.querySelector('meta[name="csrf-token"]');
			this.token = !!tokenTag ? tokenTag.getAttribute('content') : null;
			this.form = this.$refs.form;
		},
		prepareData() {
			let data = {}, items = this.form.elements;
			for (let item of items) {
				if (item.tagName === 'INPUT' || item.tagName === 'TEXTAREA') {
					data[item.name] = item.value
				}
			}
			return data;
		},
		submitForm() {
			let data = {
				data: this.prepareData()
			}, submitUrl = this.$store.getters.getBaseUrl + "/api/v1/content-manger/submit-response/rv_contact";
			grecaptcha.execute('6Lefa-UqAAAAAC_l832GLn3DqJV-15zBZkIh3spI', {action: 'submit'})
					.then((capchaToken) => {
						this.loading = true;
						Axios({
							url: submitUrl,
							method: "POST",
							headers: {
								'Content-Type': 'application/json',
								'X-CSRF-TOKEN': this.token,
								'X-Captcha-Token': capchaToken,
							},
							data: JSON.stringify(data),
						}).then((res) => {
							this.loading = false;
							this.success = true;
							this.sent = true;
						}).catch((err) => {
							this.loading = false;
							this.sent = true;
							console.log(err);
						});
					})
					.catch(function (error) {
						this.loading = false;
						this.sent = true;
					});
		},
	},
	mounted() {
		this.setUp();
	},
};
</script>
